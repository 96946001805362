import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref } from 'vue';

const storeSetup = () => {
  // State
  const isSkippedForThisSession = ref(false);
  const isModalOpen = ref(false);

  // Actions
  const setIsOnboardingSkipped = (status: boolean) => {
    isSkippedForThisSession.value = status;
  };

  return {
    // State
    isSkippedForThisSession,
    isModalOpen,

    // Actions
    setIsOnboardingSkipped,
  };
};

export const useOnboardingStore = defineStore('onboarding', storeSetup, {
  persistedState: {
    merge: (state, savedState) => {
      state.isSkippedForThisSession = savedState.isSkippedForThisSession ?? false;
      return state;
    },
    excludePaths: ['professionalismQuestions'],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOnboardingStore, import.meta.hot));
}
